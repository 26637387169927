var DEV_ENV_KEYS = ['devel', 'mock', 'dev', 'staging'];
var keyIsAbsent = function keyIsAbsent(envKey) {
  return !window.origin.includes(envKey);
};
var isLive = function isLive() {
  try {
    return DEV_ENV_KEYS.every(keyIsAbsent);
  } catch (error) {
    console.log('ERROR:', error);
    return false;
  }
};
var collectError = function collectError(error, info) {
  var _window$newrelic;
  if (process.env.NODE_ENV === 'development' || !isLive()) {
    console.log('ERROR:', error, info);
    return;
  }
  (_window$newrelic = window.newrelic) === null || _window$newrelic === void 0 || _window$newrelic.noticeError(error, info);
};
export default collectError;