var APP_BUNDLES_PATHS = {
  FM: '/fm-ui/js/main.js',
  FM_INTEGRATION: '/fm-integration-ui/js/main.js',
  BANK_DETAILS: '/bank-details-ui/js/main.js',
  INVOICES: '/invoice-settings/js/main.js',
  EMAIL_TEMPLATES: '/email-templates-ui/js/main.js',
  INVOICE_ITEMS: '/invoice-items-ui/js/main.js',
  CONTACTS_SIDEBAR: '/contacts-sidebar-ui/static/js/main.js',
  NEW_LOAD_FORM: '/new-booking-form/ui/static/js/main.js',
  WALLET: '/wallet-ui/static/js/main.js',
  MFA: '/mfa-ui/static/js/main.js',
  DATA: '/data-ui/static/js/main.js',
  DATA_SETTINGS: '/data-settings-ui/static/js/main.js',
  MOBILE_PPM: '/mobile-ppm-ui/static/js/main.js',
  LOADS_MAP: '/loads-map-ui/static/js/main.js'
};
export default APP_BUNDLES_PATHS;